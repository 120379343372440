// bootstrap variables overrides

$font-family-base: 'Inter', sans-serif;
$enable-responsive-font-sizes: true;

// font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bold_1: 600;
$font-weight-bolder: 700;
