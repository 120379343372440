@import '../../assets/theme/colors';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/functions';

@each $type, $color in $theme-colors {
  .#{$type}-icon-svg {
    & > g > circle {
      fill: $color;
    }

    & > g > g > path {
      fill: $color;
    }
  }
}
