@import '../../assets/theme/colors';
@import '../../assets/theme/fonts.scss';
@import '~bootstrap/scss/functions';
.mt-14 {
  margin-top: 6px;
}
.item-heading-text {

  color: theme-color('primary-blue');
  text-align: left;
  font: 300 14px/20px 'Inter-Light', sans-serif;
  left: 0%;
  width: 96.88%;
  bottom: 96.15%;
  top: 0%;
  height: 3.85%;
}

.item-title-text {
  color: theme-color('primary-blue');
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font: 400 14px/20px 'Inter-Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.item-date-text {
  color: theme-color('primary-blue');
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font: 300 12px/18px 'Inter-Light', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  /* 150% */
  letter-spacing: 0.1px;
}

.time-log-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}


.time-log-text {
  position: relative;
  left: 1.4rem;
}

.time-log {
  padding-left: 10px;
  position: relative;
  left: 1.4rem;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 6px;
}

.time-log::-webkit-scrollbar {
  display: none;
}

.blue-circle-container {
  display: flex;
  width: 32px;
  padding-top: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.log-container {
  padding-top: 16px;
  padding-bottom: 18px;
}

.blue-circle {
  border-radius: 20000px;
  border-style: solid;
  border-color: #585871;
  border-width: 2px;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.blue-line {
  border-style: dashed;
  border-color: #585871;
  border-width: 1px 0 0 0;
  opacity: 0.30000001192092896;
  flex-shrink: 0;
  width: 54px;
  margin-top: 0.2vh;
  margin-left: 0.450rem;
  height: 0px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(90deg) scale(1, 1);
}
