@import './colors';
@import './variables';
@import './mixins';
@import './fonts.scss';

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

//custom
.border-5 {
  border-width: 5px !important;
}

.font-small {
  font-size: 12px !important;
}

.font-medium {
  font-size: 14px !important;
}

.font-normal {
  font-size: 16px !important;
}

.animate-rotation {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}

.icon-status-rejected {
  > g {
    fill: $red;
    stroke: $red;
  }
}

.icon-status-approved {
  > g {
    fill: $blue;
    stroke: $blue;
  }
}

.border-grey {
  border-bottom: 2px solid rgba(182, 188, 209, 0.43);
}

.border-grey-1px {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
