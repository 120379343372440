@import '~bootstrap/scss/functions';

/* make the customizations */

$blue: #3e47ac;
$red: #c83c3e;
$orange: #f4aa39;
$buttercup: #f5a623;
$grey: #f8f9fb;
$cyan: #3cddcb;

$theme-colors: (
  'primary': #3e47ac,
  'grey': #f8f9fb,
  'success':lighten(#2EA692, 25%),
  'success-text': #2EA692,
  'white': white,
  'warning': lighten(#ff7d19, 25%),
  'warning-text': #ff7d19,
  'danger-text': $red,
  'danger': lighten(#c83c3e, 25%),
  'buttercup': $buttercup,
  'info': lighten(#01A8CD, 25%),
  'info-text': #01A8CD,
  'cyan': $cyan,
  'dark-blue': #111135,
  'red': $red,
  'blue-violet': #4b1574,
  'baby-powder': #fffefc,
  'primary-blue': #291482,
  'secondary-blue': #e1defb,
  'secondary-blue-hover': #cbc5ff,
  'slate-gray': #585871,
  'primary-orange': #fef2df,
  'primary-green': #a4ff00,
  'secondary-red': #fbeef2,
  'teal400': #2ea692,
  'teal200': #d8fff8,
  'secondary-green': #f3ffdd,
  'cloudy-blue': #c3c8db,
  'light-gray': #d9d9d9,
);