.swiper {
  min-height: 230px;
}
.swiper-wrapper {
  align-items: center;
  width: 171px !important;
  height: 224px;
  border-radius: 6px !important;
  padding-bottom: 24px; // for pagination
}
.swiper-slide {
  height: 224px;
  display: flex;
  justify-content: center;
  transition: height 0.4s;
  position: relative;
  overflow: hidden;
  border-radius: 6px !important;
}
.image-parent-container {
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  display: flex;
}
.image-shadow-text {
  height: 50%;
  width: 100%;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  color: white;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 4.59%, rgba(23, 23, 23, 0) 100%);
  font-family: 'Inter Medium';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
}
.pagination-dots {
  height: 7px;
  width: 7px;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 8px;
  margin-right: 10px;
  background-color: #d9d9d9;
  transition: background-color 0.5s, transform 0.5s;
  cursor: pointer;
}
.pagination-dots-active {
  transform: scale(1.3);
  transition: transform 0.5s;
  height: 6px;
  margin-right: 10px;
  width: 6px;
  border-radius: 8px;
  margin-bottom: 2px;
  margin-top: 2px;
  background-color: #291482;
}

.bag-image {
  position: absolute;
  z-index: 999;
  right: 0px;
  padding-bottom: 16px;
  padding-right: 16px;
  bottom: 0px;
  cursor: pointer;
}
.swiper-button-next::after {
  display: none;
}
.swiper-button-prev::after {
  display: none;
}


.outer-slider.swiper-slide.swiper-slide-active {
  width: 172px !important;
  height: 224px !important;
}


.outer-slider.swiper-slide {
  width: 144px !important;
  height: 224px !important;
  margin-right: 24px !important;
}
