@import '../../assets/theme/colors';
@import '../../assets/theme/fonts';

.toast-container {
  border-radius: 6px;
  padding: 0;
  font-family: 'Inter Bold Heavy';
  color: theme-color('teal400');
  letter-spacing: 0.4px;
  line-height: 21px;
  background-color: theme-color('teal200');
}
.toast-message-container {
  display: flex;
  width: '100%';
  padding: 15px;
  margin-top: 10px;
  gap: 10px;
  justify-items: center;
  align-items: center;
}
.toast-message {
  font-size: 16px;
}
