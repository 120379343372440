@font-face {
  font-family: 'Inria Serif Bold';
  src: url('../../assets/fonts/Inria_Serif/InriaSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inria Serif Light';
  src: url('../../assets/fonts/Inria_Serif/InriaSerif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Light'; //regular
  src: url('../../assets/fonts/Inter/static/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Bold';
  src: url('../../assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Medium';
  src: url('../../assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Regular';
  src: url('../../assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Bold Heavy';
  src: url('../../assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Semi Bold';
  src: url('../../assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter H1 Bold';
  src: url('../../assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
